import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Offcanvas } from "react-bootstrap";

function SiteNavDisclaimer(){

    return (
        
      <div id="header" className="header">
        <Navbar bg="white" expand={false} className="nav-padding">
            <Navbar.Brand className='brand-name' href="/">Ask <span className="brand-name-s">Ayana</span><span className="brand-name-orange-dot">.</span></Navbar.Brand>
            <Navbar.Toggle className="btn-hamburger" aria-controls="offcanvasNavbar" >
              <span className="hamburger">
                <span className="top-bun"></span>
                <span className="meat"></span>
                <span className="bottom-bun"></span>
              </span>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className="menu-main text-center flex-grow-1 pe-3">

                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/About">About</Nav.Link>
                <Nav.Link href="/Disclaimer">Disclaimer</Nav.Link>
                <Nav.Link href="/Contact">Contact</Nav.Link>
                
              </Nav>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
          
        </Navbar>
      </div>
    );

}

export default SiteNavDisclaimer;