import React, { useState } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button'
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'
import {ReactComponent as Like} from '../icons/bx-like.svg';
import {ReactComponent as Dislike} from '../icons/bx-dislike.svg';
import {ReactComponent as SearchAlt} from '../icons/bx-search-alt.svg';



function Askayana() {
    /* All <Route path> and <Link to> values in this
    component will automatically be "mounted" at the
    /users URL prefix since the <Users> element is only
    ever rendered when the URL matches /users/*
    */

    let textInput = React.createRef();  // React use ref to get input value

    // Declare a new state variable, which we'll call "data"
    const [data, setData] = useState([]);
    const [question , setQuestion] = useState("");
    const [loaded, setLoaded] = useState(true)
    const [has_answer, setHasAnswer] = useState(true);

    const environment = process.env.REACT_APP_ENVIRONMENT

    var serverAddress = environment === "PRD" ? process.env.REACT_APP_API_URL :`${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}`
    
    const fetchData = async (query) => {

        if(query.length > 0) {
            setLoaded(false);
            // You can await here
            const response = await axios(
                `${serverAddress}/api/v1.0/askayana/question/${query}`,
            );
            // ...
            setQuestion(query)

            if(response.data.length > 0){
                setHasAnswer(true)
                setData(response.data);
            }
            else{
                setHasAnswer(false)
                setData([]);
            }

            setLoaded(true);
        }
        else {
            setData([]);
        }
    }
    
      const postData = async (question, document_id, answer, is_answer) => {
          
        var feedbackData = {
            question: question,
            document_id: document_id,
            answer: answer,
            is_answer: is_answer
          }

        await axios.post(`${serverAddress}/api/v1.0/askayana/saveFeedback`, feedbackData);
      }

    let sanitizeData = (text)=> {
        text = text.replace(/[^a-z0-9áéíóúñü .,_-]/gim,"");
        return text.trim();
    }
    
    let onClickHandler = (e) =>{
        e.preventDefault();
        var question = textInput.current.value

        fetchData(sanitizeData(question))
    };

    let  saveFeedbackEventClick = (question, document_id, answer, is_answer) => (event) => {
        event.target.setAttribute("fill", "#dc623d");
        postData(question, document_id, answer, is_answer);
      }

      let formattedResultList = (data) =>{

        var html = data.map((item, index) => (
            <Card key={index} className='mb-3'>
                <div className="card-body overflow-hidden position-relative">
                    <div className="faq-count">
                        <h5 >{('0' + (parseInt(index+ 1))).slice(-2)}.</h5>
                    </div>

                    <p className="mt-3 mb-0">
                        <span dangerouslySetInnerHTML={{__html: item.context}}></span>
                    </p>
                    <br />
                    <p>
                        <i>
                            <a className="text-muted" target="_blank" rel="noreferrer" href={item.url}>{item.source}</a>&nbsp;
                            <FontAwesomeIcon className='text-muted' size="xs" icon={faExternalLinkAlt} />
                        </i>
                    </p>
                </div>
                <div className="card-footer text-muted">
                    <Row className='float-end'>
                        <Col>
                            <span className='feedback-message'>Is this a relevant response?</span>
                            <span className='star-like'>

                                <Like width="20" height="20" onClick={saveFeedbackEventClick(question, item.document_id, item.answer, true)} />
                                
                                <Dislike width="20" height="20"  onClick={saveFeedbackEventClick(question, item.document_id, item.answer, false)} />

                            </span>
                        
                        </Col>
                    </Row>
                </div>
            </Card>
        ))
        return html
    }  

    let loaderContent = () => {
        // var html = <div>searching ...</div>;

        var html = <div className='pre-loader text-center'><svg version="1.1" 
                                                                id="L7" 
                                                                xmlns="http://www.w3.org/2000/svg" 
                                                                xmlnsXlink="http://www.w3.org/1999/xlink" 
                                                                x="0px" 
                                                                y="0px" 
                                                                viewBox="0 0 100 100" 
                                                                enableBackground="new 0 0 100 100" 
                                                                xmlSpace="preserve">
                    <path fill="#10101029" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                        c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                            <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="2s" 
                            from="0 50 50"
                            to="360 50 50" 
                            repeatCount="indefinite" />
                        </path>
                    <path fill="#10101029" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                        c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                            <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="1s" 
                            from="0 50 50"
                            to="-360 50 50" 
                            repeatCount="indefinite" />
                        </path>
                    <path fill="#10101029" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                        L82,35.7z">
                            <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="2s" 
                            from="0 50 50"
                            to="360 50 50" 
                            repeatCount="indefinite" />
                        </path>
                    </svg></div>
        return html
    }
    return (
        <div className='page-content'>

            <div className="jumbotron">
                <Container>
                    <h1>Ask a COVID-19 Related Question</h1>
                    <h3>Last updated {process.env.REACT_APP_LAST_UPDATED_DATE}</h3>
                    This tool serves two purposes:
                    <ol>
                        <li key={1}>To collect and evaluate a Question Answering (QA) dataset to improve existing QA and search methods</li>
                        <li key={2}>To provide trustworthy answers to questions about COVID-19 via Natural Language Processing (NLP)</li>
                    </ol>
                    Please let <b>AYANA</b> (the AI model) know whether or not it was successful at retrieving a response by using the thumbs-up <Like width="15" height="15" /> thumbs-down <Dislike width="15" height="15" /> feedback box located at the bottom of every answer. This is how it will learn and get better!
                    
                    <form className="app-search d-lg-block mt-4" onSubmit={onClickHandler}>
                        <div className="position-relative">
                            <input required type="text" className="form-control" placeholder="Ask A Question..." ref={textInput} id="query" name="q"/>
                            <Button className="btn-primary" type="submit">
                                <SearchAlt fill="#ffffff" width="20" height="20" className="align-middle" />
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
          <Container>
            
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col xl={12} sm={12}>
                        { loaded 
                                ?  has_answer
                                            ? formattedResultList(data)
                                            :  <div>Sorry I have yet to learn about this topic ...</div>
                                : loaderContent()
                        }
                        </Col>
                    </Row> 
                </Col>       
            </Row>
          </Container>
        </div>
    );
  }

  export default Askayana;