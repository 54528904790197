
import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import SiteNavDisclaimer from './components/siteNav';
import Home from './components/home';
import About from './components/about';
import Disclaimer from './components/disclaimer';
import Contact from './components/contact';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

window.name = "John"


function App() {

  return (

    <BrowserRouter>

      <Container fluid>
        <SiteNavDisclaimer />
      </Container> 

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="contact" element={<Contact />} />
        </Routes>

    </BrowserRouter>
  );
}
export default App;

