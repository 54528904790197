import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container';

import {ReactComponent as IconEmail} from '../icons/bx-envelope.svg';
import {ReactComponent as IconLink} from '../icons/bx-link.svg';
import {ReactComponent as IconLinkedin} from '../icons/bxl-linkedin.svg';

function Contact() {
  
    return (
      <div className='page-content'>

        <div className="jumbotron margin-top-xl">
          <Container>
            <Row className='align-items-center'>
              <Col lg={6} className='offset-lg-1  float-sm-end'>

                  <h3 className="font-weight-semibold line-height-1_4 mb-4">
                    <b>— Any questions ?</b> Reach out 
                  </h3>
                      <p>We welcome any feedback, suggestions or questions on the site.</p>
              </Col>
              <Col lg={5} className='float-sm-start'>
                <div className="mt-4 mt-lg-0">
                  <p className='contact-link'>
                    <a href="mailto:kodii@kodiilabs.com" target="_blank" rel="noreferrer"><IconEmail width="20" height="20" /> : <span>kodii@kodiilabs.com</span></a>
                  </p>
                  <p className='contact-link'>
                    <a href='http://www.kodiilabs.com' target="_blank" rel="noreferrer"><IconLink width="20" height="20" /> : <span>kodiilabs.com</span></a>
                  </p>
                  <p className='contact-link'>
                    <a href='https://www.linkedin.com/in/yao-kouadio' target="_blank" rel="noreferrer"><IconLinkedin width="20" height="20" /> : <span>Yao Kouadio</span></a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  export default Contact;