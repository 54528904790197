import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function About() {
  
    return (
      <div className='page-content'>
        <div className="jumbotron">
            <Container>
              <Row className='align-items-center'>
                <Col lg={5} className='float-sm-end'>
                  <h3 className="font-weight-semibold line-height-1_4 mb-4">
                    <b>Project Description</b>
                  </h3>
                  <p className="font-weight-medium text-uppercase mb-2">
                    <i className="mdi mdi-chart-bubble h2 text-primary me-1 align-middle"></i> 
                    <span className='disclaimer'>Ask Ayana</span>
                    <br />
                    <span>...</span>
                  </p>
                </Col>
              </Row>
            </Container>
        </div>
      </div>
    );
  }

  export default About;