import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/esm/Container';

function Disclaimer() {
  
    return (
      <div className='page-content'>

        <div className="jumbotron">
          <Container>
            <Row className='align-items-center'>
              <Col lg={5} className='float-sm-end'>

                  <h3 className="font-weight-semibold line-height-1_4 mb-4">
                    Using <b>AI</b> and <b>NLP</b> to Assist with Information Extraction During the COVID-19 Pandemic
                  </h3>
                  <p className="font-weight-medium text-uppercase mb-2">
                    <i className="mdi mdi-chart-bubble h2 text-primary me-1 align-middle"></i> 
                    <span className='disclaimer'>Disclaimer</span>
                  </p>

                  <p className="text-muted font-size-15 mb-4">
                  The content of this website is provided for the information of our online visitors. It is not intended nor should it be considered as medical advice. Readers should not rely on or take any action based solely upon this information. Medical counsel should be obtained.
                  </p>
              </Col>
              <Col lg={6} className='offset-lg-1 float-sm-start'>
                <div className="mt-4 mt-lg-0">
                  <Image src='disclaimer_img.png' alt='img ayana' className='img-fluid d-block mx-auto'></Image>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  export default Disclaimer;